
import { Vue, Options } from 'vue-class-component';
import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios';
import debounce from 'lodash-es/debounce';
import { OrganisationDeviceService } from '@/services/api';
import { Device, PaginatedResponse } from '@/models';
import {
  BaseCard,
  BasePagination,
  BasePopover,
  PopoverButton,
  BaseTextInput,
  ButtonLink,
  SpecialityButton,
  DataTable
} from '@/lib/components';
import { useProgressStore } from '@/stores/progress.store';
import { useSessionStore } from '@/stores/session.store';
import { useNotificationStore } from '@/stores/notification.store';

@Options({
  components: {
    BaseCard,
    PopoverButton,
    BasePagination,
    BasePopover,
    DataTable,
    BaseTextInput,
    ButtonLink,
    SpecialityButton
  }
})
export default class DevicesPage extends Vue {
  loading = true;
  progressStore = useProgressStore();
  sessionStore = useSessionStore();
  notificationStore = useNotificationStore();

  rows: Device[] = [];
  perPage = 0;
  total = 0;
  search = '';
  request: CancelTokenSource | null = null;

  get deviceService(): OrganisationDeviceService {
    return new OrganisationDeviceService(this.organisationId);
  }

  get organisationId(): string {
    return this.$route.params.organisationId || this.sessionStore.currentOrganisationId;
  }

  get page() {
    return Number(this.$route.query.page) || 1;
  }

  get sort() {
    return this.$route.query.sort || 'updated_at';
  }

  unmounted() {
    this.progressStore.removeProgress();
    if (this.request) {
      this.request.cancel();
    }
  }

  async created() {
    this.$watch('organisationId', async () => {
      if (Object.keys(this.$route.query).length) {
        await this.$router.replace({ path: this.$route.path });
      }
      this.search = String(this.$route.query.search || '');
      await this.fetchDevices();
    });
    this.search = String(this.$route.query.search || '');
    await this.fetchDevices();
    const unWatchRoute = this.$watch('$route', async (to, from) => {
      if (from.path === to.path && from.query !== to.query) {
        await this.fetchDevices(to.query);
        window.scroll({
          top: 0,
          behavior: 'smooth'
        });
      } else {
        unWatchRoute();
      }
    });
  }

  async fetchDevices(query: any = null) {
    this.progressStore.startProgress();
    this.request = axios.CancelToken.source();
    const requestConfig: AxiosRequestConfig = {
      params: {
        page: query ? query.page : this.page,
        sort: query ? query.sort : this.sort,
        ...(this.search ? { search: this.search } : {})
      },
      cancelToken: this.request.token
    };

    try {
      const response = (await this.deviceService.index(requestConfig)) as PaginatedResponse<Device[]>;
      this.request = null;
      this.rows = response.data;
      this.perPage = response.meta.per_page;
      this.total = response.meta.total;
      this.progressStore.finishProgress();
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.progressStore.setError();
        await this.notificationStore.addErrorNotification({
          title: this.$t('platform.device.fetch-error')
        });
      }
    } finally {
      this.loading = false;
    }
  }

  get updateSearchDebounced() {
    return debounce(() => this.updateSearch(), 500);
  }

  async updateSearch() {
    // Maintain sort order and only add search param when non-empty
    await this.$router.replace({
      path: this.$route.path,
      query: {
        ...(this.$route.query.sort ? { sort: this.$route.query.sort } : {}),
        ...(this.search ? { search: this.search } : {})
      }
    });
  }

  async changePage(page: number) {
    // Keep all existing query parameters
    await this.$router.replace({
      path: this.$route.path,
      query: {
        ...this.$route.query,
        page: page.toString()
      }
    });
  }

  editDevice(deviceId: string) {
    this.$router.push(`devices/${deviceId}`);
  }

  async deleteDevice(id: string) {
    try {
      this.loading = true;
      await this.deviceService.delete(id);
      this.notificationStore.addSuccessNotification({
        title: this.$t('platform.device.delete-success')
      });
      this.fetchDevices();
    } catch (error) {
      this.notificationStore.addErrorNotification({
        title: this.$t('platform.device.delete-error')
      });
    } finally {
      this.loading = false;
    }
  }
}
