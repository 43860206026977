import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex items-center justify-between" }
const _hoisted_2 = { class: "text-lg font-semibold text-gray-900" }
const _hoisted_3 = { class: "flex" }
const _hoisted_4 = { class: "mt-8" }
const _hoisted_5 = { class: "font-medium text-gray-900" }
const _hoisted_6 = { class: "flex flex-row items-center justify-end" }
const _hoisted_7 = { class: "inline-block text-sm text-gray-500" }
const _hoisted_8 = { class: "inline-block ml-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseTextInput = _resolveComponent("BaseTextInput")!
  const _component_ButtonLink = _resolveComponent("ButtonLink")!
  const _component_SpecialityButton = _resolveComponent("SpecialityButton")!
  const _component_PopoverButton = _resolveComponent("PopoverButton")!
  const _component_BasePopover = _resolveComponent("BasePopover")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_BasePagination = _resolveComponent("BasePagination")!
  const _component_BaseCard = _resolveComponent("BaseCard")!
  const _directive_allow = _resolveDirective("allow")!

  return (_openBlock(), _createBlock(_component_BaseCard, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t('platform.settings.devices')), 1),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_BaseTextInput, {
            modelValue: _ctx.search,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
            "data-dusk": "device-search-input",
            icon: "search",
            placeholder: _ctx.$t('platform.common.search'),
            size: "small",
            onKeyup: _ctx.updateSearchDebounced
          }, null, 8, ["modelValue", "placeholder", "onKeyup"]),
          _withDirectives((_openBlock(), _createBlock(_component_ButtonLink, {
            to: "devices/new",
            "data-dusk": "new-device-button",
            class: "ml-4",
            "left-icon": "add"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('platform.device.new')), 1)
            ]),
            _: 1
          })), [
            [_directive_allow, 'device:create']
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_DataTable, {
          loading: _ctx.loading,
          columns: [
          { name: 'device_name', label: _ctx.$t('platform.device.device'), sortable: true },
          { name: 'actions', label: '', align: 'right' }
        ],
          rows: _ctx.rows,
          sort: _ctx.sort,
          class: "w-full mt-6",
          "show-table-header": false
        }, {
          "cell.device_name": _withCtx(({ row }) => [
            _createElementVNode("div", _hoisted_5, _toDisplayString(row.deviceModel.manufacturer) + " " + _toDisplayString(row.deviceModel.modelName), 1)
          ]),
          "cell.actions": _withCtx(({ row }) => [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, _toDisplayString(row.customLabel), 1),
              _createElementVNode("div", _hoisted_8, [
                _withDirectives((_openBlock(), _createBlock(_component_BasePopover, { position: "left" }, {
                  button: _withCtx(() => [
                    _createVNode(_component_SpecialityButton, {
                      "data-dusk": `device-${row.id}-actions-button`
                    }, null, 8, ["data-dusk"])
                  ]),
                  menu: _withCtx(() => [
                    _withDirectives((_openBlock(), _createBlock(_component_PopoverButton, {
                      "data-dusk": `edit-device-${row.id}`,
                      onClick: ($event: any) => (_ctx.editDevice(row.id))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('platform.common.edit')), 1)
                      ]),
                      _: 2
                    }, 1032, ["data-dusk", "onClick"])), [
                      [_directive_allow, 'device:update']
                    ]),
                    _withDirectives((_openBlock(), _createBlock(_component_PopoverButton, {
                      "data-dusk": `delete-device-${row.id}`,
                      onClick: ($event: any) => (_ctx.deleteDevice(row.id))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('platform.device.delete')), 1)
                      ]),
                      _: 2
                    }, 1032, ["data-dusk", "onClick"])), [
                      [_directive_allow, 'device:delete']
                    ])
                  ]),
                  _: 2
                }, 1024)), [
                  [_directive_allow, 'device:update']
                ])
              ])
            ])
          ]),
          _: 1
        }, 8, ["loading", "columns", "rows", "sort"]),
        (_ctx.total && _ctx.total > _ctx.perPage)
          ? (_openBlock(), _createBlock(_component_BasePagination, {
              key: 0,
              "md-pages-limit": 5,
              "sm-pages-limit": 5,
              "model-value": _ctx.page,
              "items-name": _ctx.$t('platform.settings.devices').toLowerCase(),
              "page-size": _ctx.perPage,
              length: _ctx.total,
              "onUpdate:modelValue": _ctx.changePage
            }, null, 8, ["model-value", "items-name", "page-size", "length", "onUpdate:modelValue"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}